import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Scrollspy from 'react-scrollspy'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { getEmSize } from '../styles/mixins'
import { useWindowSize } from '../helpers/useWindowSize'
import { heights, colors, mq, bp } from '../styles/variables'
import Container from './Container'

import LogoSvg from '../images/logo.svg'
import LogoStickySvg from '../images/logo_sticky.svg'
import LinkedInSvg from '../images/icons/linkedin.svg'
import CloseIcon from './case-study/helpers/CloseIcon'

type StickyProps = {
  isSticky: boolean
}

const StyledHeader = styled.header<StickyProps>`
  width: 100%;
  background: ${(props: StickyProps) => (props.isSticky ? colors.brand : 'transparent')};
  height: ${(props: StickyProps) => (props.isSticky ? heights.header : heights.headerInitial)}px;
  position: fixed;
  z-index: 1000;
  transform: translate3d(0, 0, 0);
  transition: all 200ms ease-in;
  padding: ${(props: StickyProps) => (props.isSticky ? 0 : 100)}px 20px;
  overflow: hidden;
  ${mq('sm')} {
    padding: ${(props: StickyProps) => (props.isSticky ? 0 : 100)}px 50px;
  }
  span {
    color: ${(props: StickyProps) => (props.isSticky ? colors.white : colors.brand)};
    display: ${(props: StickyProps) => (props.isSticky ? 'block' : 'none')};
  }

  a {
    color: ${(props: StickyProps) => (props.isSticky ? colors.white : colors.brand)};

    &:after {
      background: ${(props: StickyProps) => (props.isSticky ? colors.white : colors.brand)};
    }
    &.is-current {
      &:after {
        background: ${(props: StickyProps) => (props.isSticky ? colors.white : colors.brand)};
      }
    }
  }
`
const LinkedInIcon = styled(LinkedInSvg)`
  transition: all 200ms ease-in;
`
const CrossImage = styled('img')({
  height: 40,
  objectFit: 'contain',
  width: 40
})

const LinkedIn = styled.a<StickyProps>`
  margin-top: ${(props: StickyProps) => (props.isSticky ? '5px' : '-10px')};
  svg {
    .fill-color {
      transition: fill 200ms ease-out;
      fill: ${(props: StickyProps) => (props.isSticky ? colors.white : colors.brand)};
    }
    .stroke-color {
      transition: stroke 200ms ease-out;
      stroke: ${(props: StickyProps) => (props.isSticky ? colors.white : colors.brand)};
    }
    .circle-bg {
        transition: fill 200ms ease-out;
        fill: ${(props: StickyProps) => (props.isSticky ? colors.brand : 'none')};
    }

    &:hover {
      .fill-color {
        transition: fill 200ms ease-out;
        fill: ${(props: StickyProps) => (props.isSticky ? colors.brand : colors.white)};
      }
      .stroke-color {
        transition: stroke 200ms ease-out;
        stroke: ${(props: StickyProps) => (props.isSticky ? colors.brand : colors.brand)};
      }
      .circle-bg {
        transition: fill 200ms ease-out;
        fill: ${(props: StickyProps) => (props.isSticky ? colors.white : colors.brand)};
    }
      }
    }
  }
`
const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`

const HomepageLink = styled(AnchorLink)`
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 8px;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`
const NavContainer = styled.div`
  text-align: right;
`
const NavTitle = styled('span')`
  display: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${getEmSize(16)}em;
  padding: 1.2rem 25px;
  position: relative;
  transition: all 200ms ease-in;
  ${mq('md')} {
    display: block;
  }

  ${mq('xxl')} {
    padding: 1.2rem 50px;
  }
`

const NavLink = styled(AnchorLink)`
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  font-size: ${getEmSize(16)}em;
  padding: 1.2rem 25px;
  position: relative;
  transition: all 200ms ease-in;

  ${mq('xxl')} {
    padding: 1.2rem 50px;
  }

  &:hover {
    text-decoration: none;
  }

  &:after {
    content: ' ';
    display: block;
    height: 2px;
    margin: 10px auto 0;
    transition: all 0.3s ease-out;
    background: ${colors.brand};
    transform: scaleX(0);
    width: 60%;
  }

  &:hover:after {
    transform: scaleX(1);
  }
`

interface IHeaderLinks {
  name: string
  href: string
}

interface HeaderProps {
  title: string
  links: IHeaderLinks[]
  closeTo?: string
}

const Header: React.FC<HeaderProps> = ({ links, closeTo, title }) => {
  const [toggleSticky, setSticky] = useState(false)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isSticky = currPos.y < -heights.header
      if (isSticky !== toggleSticky) setSticky(isSticky)
    },
    [toggleSticky]
  )
  const size = useWindowSize()

  const navScrollItems = ['top', ...links?.map(x => x.href.replace('#', ''))]

  const isMobile = size.width < bp.xl

  return (
    <StyledHeader isSticky={toggleSticky}>
      <HeaderInner>
        {closeTo ? (
          <a
            href={closeTo}
            css={css`
              padding-right: 0;
              /* ${mq('lg')} {
                padding-right: ${toggleSticky ? '120px' : 0};
              } */
            `}
          >
            {toggleSticky ? (
              <LogoStickySvg />
            ) : (
              <LogoSvg
                css={{
                  width: 200,
                  padding: 0,
                  overflow: 'visible',
                  [mq('md')]: {
                    width: 300
                  }
                }}
              />
            )}
          </a>
        ) : (
          <HomepageLink
            href="#top"
            css={css`
              padding-right: 0;
              /* ${mq('lg')} {
                padding-right: ${toggleSticky ? '120px' : 0};
              } */
            `}
          >
            {toggleSticky ? (
              <LogoStickySvg />
            ) : (
              <LogoSvg
                css={{
                  width: 200,
                  padding: 0,
                  overflow: 'visible',
                  [mq('md')]: {
                    width: 300
                  }
                }}
              />
            )}
          </HomepageLink>
        )}
        {closeTo && (
          <NavTitle
            css={{
              opacity: toggleSticky ? '1' : '0'
            }}
          >
            {title}
          </NavTitle>
        )}
        {!isMobile && (
          <NavContainer
            css={{
              display: 'none',
              [mq('xl')]: {
                display: 'block'
              }
            }}
          >
            <Scrollspy
              items={navScrollItems}
              offset={-500}
              currentClassName="is-current"
              css={css`
                list-style: none;
                padding-left: 0;
                margin-top: ${toggleSticky ? '30px' : '20px'};
                display: flex;
                align-items: center;

                li {
                  &.is-current {
                    a:after {
                      content: ' ';
                      background: ${toggleSticky ? colors.white : colors.brand};
                      display: block;
                      height: 2px;
                      margin: 10px auto 0;
                      transition: all 0.3s ease-out;
                      transform: scaleX(1);
                      width: 60%;
                    }
                  }
                }
              `}
            >
              <li css={{ display: 'none' }}>
                <a href="#top">Top</a>
              </li>

              {links?.map((link, i) => {
                return (
                  <li key={i}>
                    <NavLink offset={heights.header} href={link.href}>
                      {link.name}
                    </NavLink>
                  </li>
                )
              })}
            </Scrollspy>
          </NavContainer>
        )}
        {closeTo ? (
          <LinkedIn href={closeTo} isSticky={toggleSticky}>
            <CloseIcon fillColor={toggleSticky ? colors.white : colors.brand} />
          </LinkedIn>
        ) : (
          <LinkedIn href="https://www.linkedin.com/company/rumble-asia/" target="_blank" isSticky={toggleSticky}>
            <LinkedInIcon />
          </LinkedIn>
        )}
      </HeaderInner>
    </StyledHeader>
  )
}

export default Header
